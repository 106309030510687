import { queryDetailConstants } from '../constants/querydetail.constants';

const initialState = {
    queryListingRequest: {},
    queryDetailResponse: {},
    error: '',
    success: '',
    showQueryDetailLoader: false
};

export function query(state = initialState, action) {
    switch (action.type) {
        case queryDetailConstants.GETQUERYDETAIL_REQUEST:
            return {
                ...state,
                showQueryDetailLoader: action.showQueryDetailLoader
            };
        case queryDetailConstants.GETQUERYDETAIL_REQUEST_END:
            return {
                ...state,
                showQueryDetailLoader: action.showQueryDetailLoader
            };
        case queryDetailConstants.GETQUERYDETAIL_SUCCESS:
            return {
                ...state,
                queryDetailResponse: action.queryDetailResponse

            };
        case queryDetailConstants.GETQUERYDETAIL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case queryDetailConstants.CLEAR_ALL_MESSAGE:
            return {
                ...state,
                error: '',
                success: ''
            };
        default:
            return state
    }
}
export const Constant = {
    EncryptionKey: "my-secret-key@123",
    RememberMeKey: "rememberMe",
    AccessToken: "accessToken",
    RefreshToken: "refreshToken",
    SortOrderConstant: [{ label: "Asc", value: "Asc" }, { label: "Desc", value: "Desc" }, { label: "None", value: "None" }],
    Primary: "Primary",
    Secondary: "Secondary",
    AddressesInLower: "addresses",
    OrganizationsInLower: "organizations",
    TableType: "tableType",
    MDGOrgidInLower: "mdgorgid",
    MDGidInLower: "mdgid",
    Zip: "Zip",
    NPIZip: "NPI ZIP",
    SuppressionFieldName: "Suppression/Match",
    SuppressionTableName: "Suppression/Match",
    BehavioralMarketsFieldName: "Behavioral_Markets",
    BehavioralFunctionTableName: "Behavioral Functions",

    listLookupCondition: [
        { "label": "Is in list" },
        { "label": "Is not in list" },
        { "label": "Is empty" },
        { "label": "Is not empty" }
    ],
    listZipcodeCondition: [
        { "label": "Equals" },
        { "label": "Does not equal" },
        { "label": "Is empty" },
        { "label": "Is not empty" },
        { "label": "Is in list" },
        { "label": "Is not in list" }
    ],
    listJobtitleCondition: [
        { "label": "Contains" },
        { "label": "Does not contain" }
    ],
    listIndustryCondition: [
        { "label": "Include" },
        { "label": "Exclude" }
    ],
    listTextCondition: [
        { "label": "Equals" },
        { "label": "Does not equal" },
        { "label": "Contains" },
        { "label": "Does not contain" },
        { "label": "Begins with" },
        { "label": "Does not begin with" },
        { "label": "Ends with" },
        { "label": "Does not end with" },
        { "label": "Is empty" },
        { "label": "Is not empty" }
    ],
    listColumnNumericDateCondition: [
        { "label": "Equals" },
        { "label": "Does not equal" },
        { "label": "Is greater than" },
        { "label": "Is greater than or equal to" },
        { "label": "Is less than" },
        { "label": "Is less than or equal to" },
        { "label": "Is between" },
        { "label": "Is not between" },
        { "label": "Is empty" },
        { "label": "Is not empty" }
    ],
    aimxrMonthYearFilterCondition: [
        { "label": "Is in list" },
        { "label": "Is not in list" }
    ],
    suppressionFilterCondition: [
        { "label": "Is in list" },
        { "label": "Is not in list" }
    ],
    behaviourlMarketFilterCondition: [
        { "label": "Is in list", "value": "Equals" },
        { "label": "Is not in list", "value": "Does not equal" }
    ],

    listBehavioralCodeCondition: [
        { "label": "Equals" },
        { "label": "Does not equal" }
    ],
    listAIMXRKeywordCondition: [
        { "label": "Equals" },
        { "label": "Does not equal" }
    ],
    listCondition: [
        { "label": "Is in list" },
        { "label": "Is not in list" }
    ],
    IsInListLower: "is in list",
    IsNotInListLower: "is not in list",
    Search: "Search",
    DecileCountOptions: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
        { label: "10", value: 10 }],
    DataTypeConstant: [{ label: "Text", value: "Text" }, { label: "Integer", value: "Integer" }, { label: "Numeric", value: "Numeric" }, { label: "DateTime", value: "DateTime" }, { label: "Lookup", value: "Lookup" }],
    DataFieldSortOrderConstant: [{ label: "None", value: "None" }, { label: "Numeric", value: "Numeric" }, { label: "Alphabetical", value: "Alphabetical" }, { label: "Chronological", value: "Chronological" }],
    UserGroupConstant: [
        { label: 'Admin', value: 'Admin' },
        { label: 'DMS User', value: 'DMS User' },
        { label: 'Sales', value: 'Sales' },
        { label: 'Operations', value: 'Operations' },
    ],
    ApprovalStatusConstant: [
        { label: 'Pending', value: 'Pending' },
        { label: 'Approved', value: 'Approved' },
        { label: 'Rejected', value: 'Rejected' }
    ],
    KeyCodes :{
        comma: 188,
        enter: 13,
    },
    CREATEUPDATE: 'Create/Update',
    VIEW: 'View',
    DELETE: 'Delete',
    IMPORT:'Import',
    EXPORT:'Export',
    ISCUSTOMFIELD:'IsCustomField',
    RESTRICTACCESSTOOWNACTIVITIES:'Restrict access to own activities',
    ALLOWACCESSTOALLACTIVITIES:'Allow access to all activities',
    DISTRIBUTEDSPBUTTON:'Distribute DSP Button',
    LIVERAMPDISTRIBUTIONTAB:'Live Ramp Distribution Tab',
    RTADISTRIBUTIONTAB:'RTA Distribution Tab',
    ADDDISTRIBUTIONBUTTON:'Add Distribution Button',
    ACCESSTOINTERNALUSERS:'Access to Internal Users',
    ACCESSTOEXTERNALUSERS:'Access to External Users',
    ACCESSTODISTRIBUTEDSP:'Allow to Distribute DSP',

    ACCESSTOAHCPTEMPLATE: 'AHCP Template Access',
    BehavioralMarketsFileName: [
        { label: 'Procedure', value: 'ProcedureCategory' },
        { label: 'Diagnosis', value: 'DiagnosisCategory' },
        { label: 'Prescription', value: 'PrescriptionCategory' }
    ],
}
import { headerConst } from '../constants/header.constant';

const initialState = {
    plateform: '',
    userRole:''
};

export function headers(state = initialState, action) {
    switch (action.type) {
        case headerConst.GETPLATEFORMTYPE:
            return {
                ...state,
                spleshScreenData: action.responseData
            };
        case headerConst.GETROLETYPE:
            return {
                ...state,
                spleshScreenData: {},
                error: action.error
            };
        case headerConst.ADDPLATEFORMTYPE:
            return {
                ...state,
                plateform: action.plateform,
            };
        default:
            return state
    }
}

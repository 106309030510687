export const lightTheme = {
    bodybg: '#f7f7f7',
    text: '#363537',
    headerbg: '#0093e7',
    sidebarbg: '#ffffff',
    sidebartext:'#495057',
    mdgprimary: '#0093e7',
    mdgsecondary: '#e1e1e1',
    mainbg:'#ffffff',
    textgrid: '#363537',
    border:'#666666',
    iconfontcolor:'#333333',
    statusbadgebg:'#e6e9ee',
    textsamecolor:'#363537',
    boxheader:'#f9f9f9',
    labeltext:'#333333',
    tabhighlight:'#ebf7fd',
    box2:'#f4f4f4',
    formtext:'#666666',
    secondarycolor:'#e1e1e1',
    ascentgray:'#f4f4f4',
    bodyfont:'#333333',
    ascentwg:'#fff',
    ascentborder:'#dee2e6',
    loaderbg:'rgba(255, 255, 255, 0.8)',
    ascentww:'#ffffff',
    ascentwb:'#ffffff',
    inputborder:'#a1a1a1',
    rowhighlight:"#E3F2FD",
    disabled:"#e9ecef",
    ascentgrey:"#575757",
    ascentgrey1:"#575757",
    ascent2:"#cce9fa",
    ascent3:"#ffffff",
    ascent4:'#495057',
    ascent5:'#0093e7',
    ascent6:'#f2f2f2',
    ascent7:'#e1e1e1',
    ascent8:'#696969',
    ascent9: '#ffffff',
    ascent10: '#f2f2f2',
    ascent11: '#e1e1e1',
    ascent12: '#dedede',
    ascent13:'rgba(99, 223, 159, 0.15)',
    ascent14:'#0093e7'
}
export const darkTheme = {
    bodybg: '#1d1e26',
    text: '#fefefe',
    headerbg: '#262932',
    sidebarbg: '#262932',
    sidebartext:'#909bbb',
     mdgprimary: '#96c93d',
     mdgsecondary: '#e1e1e1',
     mainbg:'#262932',
     textgrid: '#ffffff',
     border:'#c1c1c1',
     iconfontcolor:'#ffffff',
     statusbadgebg:'#ffffff',
     textsamecolor:'#363537',
     boxheader:'#2e313b',
     labeltext:'#ffffff',
     tabhighlight:'#353947',
     box2:'#2e313b',
     formtext:'#ffffff',
     secondarycolor:'#3c414d',
     ascentgray:'#ffffff',
     bodyfont:'#333333',
     ascentwg:'#96c93d',
     ascentborder:'#484a51',
     loaderbg:'rgba(38, 41, 50, 0.5)',
     ascentww:'#ffffff',
     ascentwb:'#1d1e26',
     inputborder:'#484a51',
     rowhighlight:"#1d1e26",
     disabled:"#000000",
     ascentgrey:"#ffffff",
     ascentgrey1:"#ffffff",
     ascent2:"#262932",
     ascent3:"#2e313b",
     ascent4:'#ffffff',
     ascent5:'#1d1e26',
     ascent6:'#262932',
     ascent7:'#96c93d',
     ascent8:'#ffffff',
     ascent9: '#535761',
     ascent10: '#20242f',
     ascent11: '#535761',
     ascent12: '#40434a',
     ascent13:'rgba(0, 0, 0, 1)',
     ascent14:'#ffffff'
}

import { slashScreenConstants } from '../constants/splashscreen.constant';

const initialState = {
    spleshScreenData: {},
    error: '',
    success: ''
};

export function splashScreens(state = initialState, action) {
    switch (action.type) {
        case slashScreenConstants.GETSPLESHSCREEN_SUCCESS:
            return {
                ...state,
                spleshScreenData: action.responseData
            };
        case slashScreenConstants.SPLESHSCREEN_FAILURE:
            return {
                ...state,
                spleshScreenData: {},
                error: action.error
            };
        case slashScreenConstants.SAVESPLASH:
            return {
                ...state,
                success: action.model,
                error: ''
            };
        default:
            return state
    }
}

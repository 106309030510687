import { audittrailListingConstants } from '../constants/audittraillisting.constants';

const initialState = {
  audittrailListingRequest: {},
  audittrailListingResponse: {
    lstAudittrailListingResponse: [],
    totalRecords: 0
  },
  error: '',
  success: '',
  showAudittrailListingLoader: false
};

export function audittrailListing(state = initialState, action) {
  switch (action.type) {
    case audittrailListingConstants.GETALLAUDITTRAILLISTING_REQUEST:
      return {
        ...state,
        showAudittrailListingLoader: action.showAudittrailListingLoader
      };
    case audittrailListingConstants.GETALLAUDITTRAILLISTING_REQUEST_END:
      return {
        ...state,
        showAudittrailListingLoader: action.showAudittrailListingLoader
      };
    case audittrailListingConstants.GETALLAUDITTRAILLISTING_SUCCESS:
      return {
        ...state,
        audittrailListingResponse: action.audittrailListingResponse

      };
    case audittrailListingConstants.GETALLAUDITTRAILLISTING_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case audittrailListingConstants.CLEAR_ALL_MESSAGE:
      return {
        ...state,
        error: '',
        success: ''
      };
    default:
      return state
  }
}
import { roleManagementConstants } from '../constants/rolemanagement.constants';

const initialState = {
    spleshScreenData: {},
    error: '',
    success: ''
};

export function rolemanagement(state = initialState, action) {
    switch (action.type) {
        case roleManagementConstants.GETSPLESHSCREEN_SUCCESS:
            return {
                ...state,
                spleshScreenData: action.responseData
            };
        case roleManagementConstants.SPLESHSCREEN_FAILURE:
            return {
                ...state,
                spleshScreenData: {},
                error: action.error
            };
        case roleManagementConstants.SAVESPLASH:
            return {
                ...state,
                success: action.model,
                error: ''
            };
        default:
            return state
    }
}

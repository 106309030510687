import { Constant } from "../constants/constants";
var CryptoJS = require("crypto-js");

export const testtokenservice = {
    getAuthHeader,
    setAccessToken,
    setRefreshToken
};

export function getAuthHeader() {
    // return authorization header for JWT
    let user = getAccessToken();

    if (user !== undefined && user !== '') {
        //'Bearer ' 
        return user
    } else {
        return '';
    }
}

export function setAccessToken(accessToken) {
    localStorage.setItem(Constant.AccessToken, accessToken);
}
export function setRefreshToken(refreshToken) {
    localStorage.setItem(Constant.RefreshToken, refreshToken);
}

export function getAccessToken() {
    return localStorage.getItem(Constant.AccessToken);
}
export function getRefreshToken() {
    return localStorage.getItem(Constant.RefreshToken);
}

export function removeAccessToken() {
    localStorage.removeItem(Constant.AccessToken);
}
export function removeRefreshToken() {
    localStorage.removeItem(Constant.RefreshToken);
}

export function isRemeberMeSelected() {
    var userDetail = {
        UserName: '',
        Password: '',
        Type: '',
        IpAddress: '',
        IsRememberMe: false
    }
    var rememberMeData = localStorage.getItem(Constant.RememberMeKey);


    if (rememberMeData && rememberMeData.length > 0) {
        var data = JSON.parse(rememberMeData);
        var bytes = CryptoJS.AES.decrypt(data.value, Constant.EncryptionKey);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (data && data.expiry) {
            if (data.expiry.valueOf() < new Date().valueOf()) {
                localStorage.removeItem(Constant.RememberMeKey);
            }
            else {
                userDetail = decryptedData;
                userDetail.IsRememberMe = true;
            }
        }
    }
    return userDetail;

}
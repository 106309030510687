export const zerobounceListingConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',
    CLEAR_ALL_MESSAGE: 'CLEAR_ALL_MESSAGE',
    GETALLZEROBOUNCELISTING_REQUEST: 'GETALLZEROBOUNCELISTING_REQUEST',
    GETALLZEROBOUNCELISTING_REQUEST_END: 'GETALLZEROBOUNCELISTING_REQUEST_END',

    GETALLZEROBOUNCELISTING_SUCCESS: 'GETALLZEROBOUNCELISTING_SUCCESS',
    GETALLZEROBOUNCELISTING_FAILURE: 'GETALLZEROBOUNCELISTING_FAILURE'

};

export const criticalImpactLogListingConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',
    CLEAR_ALL_MESSAGE: 'CLEAR_ALL_MESSAGE',
    GETALLCRITICALIMPACTLOGLISTING_REQUEST: 'GETALLCRITICALIMPACTLOGLISTING_REQUEST',
    GETALLCRITICALIMPACTLOGLISTING_REQUEST_END: 'GETALLCRITICALIMPACTLOGLISTING_REQUEST_END',

    GETALLCRITICALIMPACTLOGLISTING_SUCCESS: 'GETALLCRITICALIMPACTLOGLISTING_SUCCESS',
    GETALLCRITICALIMPACTLOGLISTING_FAILURE: 'GETALLCRITICALIMPACTLOGLISTING_FAILURE'

};

import React, { Component } from 'react';
import { history } from '../helpers';
import { PanelMenu } from 'primereact/panelmenu';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { connect } from 'react-redux';
import { AddPlateformType } from 'actions/header.action';
import DMS_icon from '../assets/img/DMS_icon.png';
import AHCP_icon from '../assets/img/AHCP_icon.png';
import _ from 'lodash';
var CryptoJS = require("crypto-js");

interface IMenuProps {
    isMenuOpen: any;
}

class Menu extends Component<IMenuProps, {}> {
    private items: any;
    userDetail: any;
    userRole: any = 'User';
    platforms: any;
    openMenuItem: any = "";
    defaultURL: any = "";
    constructor(props) {
        super(props);
        this.state = {
            switchplatform: null
        };

        this.platforms = [];


        var userData = localStorage.getItem("user");
        if (userData && userData.length > 0) {
            var data = JSON.parse(userData);
            var bytes = CryptoJS.AES.decrypt(data, 'my-secret-key@123');
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.userDetail = decryptedData;

            if (this.userDetail.isAdmin === true) {
                this.userRole = 'Admin';
            }
            else if (this.userDetail.isSystemAdmin === true) {
                this.userRole = 'System Admin';
            }
            else {
                this.userRole = 'User';
            }

            if (this.userDetail.userProductType !== undefined && this.userDetail.userProductType.length > 0) {
                this.userDetail.userProductType.forEach((item, i) => {
                    if (item.toUpperCase() === "DMS" || item.toUpperCase() === "BOTH") {
                        if (this.platforms.some(item1 => item1.name == 'DMS') === false) {
                            this.platforms.push({ name: 'DMS', value: 'DMS' });
                        }
                    } else if (item.toUpperCase() === "AHCP" || item.toUpperCase() === "BOTH") {
                        if (this.platforms.some(item1 => item1.name == 'AccessHCP') === false) {
                            this.platforms.push({ name: 'AccessHCP', value: 'AHCP' });
                        }
                    }
                    else if (item.toUpperCase() === "HCPDI") {
                        if (this.platforms.some(item1 => item1.name == 'HCP Insights') === false) {
                            this.platforms.push({ name: 'HCP Insights', value: 'HCPDI' });
                        }
                    }
                });
            }
        }
        this.addEventListenerOnMenuClick = this.addEventListenerOnMenuClick.bind(this);
        this.removeEventListenerOnMenuClick = this.removeEventListenerOnMenuClick.bind(this);
    }

    componentDidMount() {
        this.props.addPlateform(this.platforms[0]?.value);

        var userMenu = localStorage.getItem("Menu");
        if (userMenu !== null && userMenu !== undefined && userMenu !== '') {
            this.setState({ switchplatform: userMenu });

            this.genetateMenu(userMenu);
            sessionStorage.setItem("localMenu", userMenu);

        } else {
            this.setState({ switchplatform: this.platforms[0]?.value });
            localStorage.setItem("Menu", this.platforms[0]?.value);
            this.genetateMenu(this.platforms[0]?.value);
            sessionStorage.setItem("localMenu", this.platforms[0]?.value);
        }

        setTimeout(() => {
            document.querySelector(".p-panelmenu-panel.Reporting")?.setAttribute("title", "Reporting");
            document.querySelector(".p-panelmenu-panel.Administration")?.setAttribute("title", "Administration");
            document.querySelector(".p-panelmenu-panel.Automation")?.setAttribute("title", "Automation");
            document.querySelector(".p-panelmenu-panel.Downloads")?.setAttribute("title", "Downloads");
            document.querySelector(".p-panelmenu-panel.Planning")?.setAttribute("title", "Planning");
        }, 1000);
    }

    genetateMenu(userProductType) {
        this.items = [];


        if (this.userDetail?.roleWisePermission !== undefined) {

            //var userProductWhere = "";
            if (userProductType === "HCPDI") {
             //   userProductWhere = "x.productType.toUpperCase() == 'BOTH'"
            }
            this.userDetail?.roleWisePermission.moduleList.filter(x => x.productType == userProductType ||
                (userProductType === "HCPDI" ? x.productType == userProductType : x.productType.toUpperCase() == 'BOTH')).forEach((item, i) => {

                    //if (_.isEmpty(item.url)) {

                    let childItem: any = [];

                    item.subModuleList.filter(x => x.isParentMenu === false && (x.subModuleProductType == userProductType
                        || (userProductType === "HCPDI" ? x.subModuleProductType == userProductType : x.subModuleProductType.toUpperCase() == 'BOTH'))).forEach((subModuleItem, j) => {

                            if (_.isEmpty(this.defaultURL)) {
                                this.defaultURL = subModuleItem.url;
                            }
                            childItem.push({
                                label: subModuleItem.subModuleName,
                                icon: item.menuIconClass,
                                template: () => {
                                    if (document.querySelector("." + item.moduleName + " a .pi-chevron-right") != null) {
                                        this.openMenuItem = item.moduleName;
                                        this.addEventListenerOnMenuClick(this.handleMenuClick);
                                    }
                                    return (
                                        <NavLink title={subModuleItem.subModuleName} onClick={this.handleMenuClick} 
                                        className={'text-decoration-underline-hover link-color p-menuitem-link'}
                                        //className="text-decoration-underline-hover link-color p-menuitem-link"
                                         //   style={{ color: window.location.pathname === subModuleItem.url ? '#0093e7' : '' }}
                                            to={{ pathname: subModuleItem.url }}
                                        >{subModuleItem.subModuleName}</NavLink>
                                    );
                                }
                            });
                        });

                    item.subModuleList.filter(x => x.isParentMenu === true &&
                        (x.subModuleProductType == userProductType
                            || (userProductType === "HCPDI" ? x.subModuleProductType == userProductType : x.subModuleProductType.toUpperCase() == 'BOTH'))).forEach((subModuleItem, j) => {
                                if (_.isEmpty(this.defaultURL)) {
                                    this.defaultURL = subModuleItem.url;
                                }
                                this.items.push({
                                    label: subModuleItem.subModuleName,
                                    icon: subModuleItem.menuIconClass,
                                    template: () => {
                                        return (
                                            <div className='single-menuitem' title={subModuleItem.subModuleName} >
                                                {subModuleItem.subModuleName == "Support" ? <NavLink
                                                    //className={ window.location.pathname === subModuleItem.url ? 'p-panelmenu-header-link actvieMenu' : 'p-panelmenu-header-link'}
                                                    className="p-panelmenu-header-link"
                                                    to={{ pathname: subModuleItem.url }} target="_blank" >
                                                    <span className={'p-menuitem-icon ' + subModuleItem.menuIconClass}></span>
                                                    <span title={subModuleItem.subModuleName}
                                                        className={'text-decoration-underline-hover link-color p-menuitem-text'}
                                                    //   className="text-decoration-underline-hover link-color p-menuitem-text"
                                                    //  style={{ color: window.location.pathname === subModuleItem.url ? '#0093e7' : '' }}
                                                    >{subModuleItem.subModuleName}</span>
                                                </NavLink> :
                                                    <NavLink className="p-panelmenu-header-link"
                                                        //className={ window.location.pathname === subModuleItem.url ? 'p-panelmenu-header-link actvieMenu' : 'p-panelmenu-header-link'}
                                                        to={{ pathname: subModuleItem.url }}>
                                                        <span className={'p-menuitem-icon ' + subModuleItem.menuIconClass}></span>
                                                        <span title={subModuleItem.subModuleName}
                                                            className={'text-decoration-underline-hover link-color p-menuitem-text'}
                                                        //   className="text-decoration-underline-hover link-color p-menuitem-text"
                                                        //style={{ color: window.location.pathname === subModuleItem.url ? '#0093e7' : '' }}
                                                        >{subModuleItem.subModuleName}</span>
                                                    </NavLink>}
                                            </div>
                                        );
                                    }
                                });
                            });

                    if (item.isParentMenu && item.subModuleList.filter(x => x.isParentMenu === false && (x.subModuleProductType === userProductType || x.subModuleProductType === "BOTH")).length > 0) {
                        this.items.push({
                            label: item.moduleName,
                            className: item.moduleName,
                            icon: item.menuIconClass,
                            items: childItem
                        });
                    }
                });
        }
    }

    onDropDownChange = (e) => {
        this.defaultURL = "";
        this.props.addPlateform(e.value);
        this.setState({ switchplatform: e.value });
        localStorage.setItem("Menu", e.value);

        sessionStorage.setItem("localMenu", e.value);


        this.genetateMenu(e.value);
        setTimeout(() => {
            document.querySelector(".p-panelmenu-panel.Reporting")?.setAttribute("title", "Reporting");
            document.querySelector(".p-panelmenu-panel.Administration")?.setAttribute("title", "Administration");
            document.querySelector(".p-panelmenu-panel.Automation")?.setAttribute("title", "Automation");            
            document.querySelector(".p-panelmenu-panel.Downloads")?.setAttribute("title", "Downloads");
            document.querySelector(".p-panelmenu-panel.Planning")?.setAttribute("title", "Planning");
            document.querySelector(".p-panelmenu-panel.Automation")?.setAttribute("title", "Automation");

            if(e.value === "DMS"){
                const dashboard = document.querySelectorAll("a.p-panelmenu-header-link")
                const planning = document.querySelector("a.p-panelmenu-header-link span.p-menuitem-icon.fa-file-invoice");

                dashboard?.forEach((data, index) => {
                    data?.childNodes?.forEach(sibling => {
                        sibling?.nextSibling?.classList?.forEach((data) => {
                            if(data === "fa-file-invoice"){
                                planning?.classList?.add('actvieMenu')
                                sibling?.nextSibling?.nextSibling?.classList.add("actvieMenu")
                            }
                        })
                    })
                })
            }
        }, 1000);
        if (!_.isEmpty(this.defaultURL)) {
            history.push(this.defaultURL);
        }
    }

    addEventListenerOnMenuClick(eventName) {
        //console.log('addEventListenerOnMenuClick')
        document.querySelector('.mdg-layout').addEventListener('click', eventName);
        document.querySelector('.mdg-layout-header.app-header').addEventListener('click', eventName);
        document.querySelector('.app-name .nav-left .fa-chevron-left').addEventListener('click', eventName);

        document.querySelector('.mdg-layout').addEventListener('mouseover', eventName);
        document.querySelector('.mdg-layout-header.app-header').addEventListener('mouseover', eventName);
        document.querySelector('.app-name .nav-left .fa-chevron-left').addEventListener('mouseover', eventName);
    }
    removeEventListenerOnMenuClick(eventName) {
        //console.log('removeEventListenerOnMenuClick')
        document.querySelector('.mdg-layout').removeEventListener('click', eventName);
        document.querySelector('.mdg-layout-header.app-header').removeEventListener('click', eventName);
        document.querySelector('.app-name .nav-left .fa-chevron-left').removeEventListener('click', eventName);

        document.querySelector('.mdg-layout').removeEventListener('mouseover', eventName);
        document.querySelector('.mdg-layout-header.app-header').removeEventListener('mouseover', eventName);
        document.querySelector('.app-name .nav-left .fa-chevron-left').removeEventListener('mouseover', eventName);
    }

    handleMenuClick = (moduleName) => {
        if (document.querySelector(".sidebaropen") === null && this.openMenuItem != "") {
            var str1 = ("." + this.openMenuItem + " .pi-chevron-down").toString();
            if (document.querySelector(str1) != null) {
                document.querySelector(str1).click();
            }
            this.openMenuItem = "";
            this.removeEventListenerOnMenuClick(this.handleMenuClick);
        }
    };

    navigateToPage = (path) => {
        history.push(path);
    }

    render() {

        return (
            <div>
                <div className={`side-nav${this.props.isMenuOpen === true ? '' : ' collapsed'}`}>
                    <div className="app-name">
                        <div className='switchplatform-icon text-center py-3'>
                            {
                                this.state.switchplatform == "DMS" ? <img src={DMS_icon} alt="DMS" /> : <img src={AHCP_icon} alt="AHCP" />
                            }
                            {/* <img src={DMS_icon} alt="DMS" /> */}
                            {/* <img src={AHCP_icon} alt="AHCP" /> */}
                        </div>
                        <div className='pt-3 pb-2 px-4 switch-platform-drpdn'>
                            <Dropdown appendTo={'self'} className='w-100 px-2 form-control-border'
                                value={this.state.switchplatform}
                                onChange={(e) => { this.onDropDownChange(e); }}
                                options={this.platforms} optionLabel="name" optionValue='value' placeholder="Select Platform" />
                        </div>

                        <div className="nav-left">
                            <span className="header-left-title header-menuicon" onClick={this.props.onMenuToggle}><i className="fas fa-chevron-left"></i></span>
                        </div>
                    </div>
                    <PanelMenu className='mdg-panelmenu mt-2' model={this.items} />
                </div>

            </div>
        );

    }
}

const mapDispatchToProps = dispatch => {
    return {
        addPlateform: (plateform) => dispatch(AddPlateformType(plateform))
    }
}


export default connect(null, mapDispatchToProps)(Menu);
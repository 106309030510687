import { cohortUploadListingConstants } from '../constants/cohortuploadlisting.constants';



const initialState = {
  cohortUploadListingRequest: {},
  cohortUploadListingResponse: {
    lstCohortUploadListingResponse: [],
    totalRecords: 0
  },
  error: '',
  success: '',
  showCohortUploadListingLoader: false
};

export function cohortUploadListing(state = initialState, action) {
  switch (action.type) {
    case cohortUploadListingConstants.GETALLCOHORTUPLOADLISTING_REQUEST:
      return {
        ...state,
        showCohortUploadListingLoader: action.showCohortUploadListingLoader
      };
    case cohortUploadListingConstants.GETALLCOHORTUPLOADLISTING_REQUEST_END:
      return {
        ...state,
        showCohortUploadListingLoader: action.showCohortUploadListingLoader
      };
    case cohortUploadListingConstants.GETALLCOHORTUPLOADLISTING_SUCCESS:
      return {
        ...state,
        cohortUploadListingResponse: action.cohortUploadListingResponse

      };
    case cohortUploadListingConstants.GETALLCOHORTUPLOADLISTING_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case cohortUploadListingConstants.CLEAR_ALL_MESSAGE:
      return {
        ...state,
        error: '',
        success: ''
      };
    default:
      return state
  }
}
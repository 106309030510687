import { userListingConstants } from '../constants/userlisting.constants';



const initialState = {
  userListingRequest: {},
  userListingResponse: {
    lstUserListingResponse: [],
    totalRecords: 0
  },
  error: '',
  success: '',
  showUserListingLoader: false
};

export function userListing(state = initialState, action) {
  switch (action.type) {
    case userListingConstants.GETALLUSERLISTING_REQUEST:
      return {
        ...state,
        showUserListingLoader: action.showUserListingLoader
      };
    case userListingConstants.GETALLUSERLISTING_REQUEST_END:
      return {
        ...state,
        showUserListingLoader: action.showUserListingLoader
      };
    case userListingConstants.GETALLUSERLISTING_SUCCESS:
      return {
        ...state,
        userListingResponse: action.userListingResponse

      };
    case userListingConstants.GETALLUSERLISTING_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userListingConstants.CLEAR_ALL_MESSAGE:
      return {
        ...state,
        error: '',
        success: ''
      };
    default:
      return state
  }
}
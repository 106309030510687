import { useState, useEffect } from 'react';
import { SplashService } from 'services/splashscreen.service';
import { Dialog } from 'primereact/dialog';
import { decodeEntities } from 'helpers/commonFunctions';
import { Constant } from '../../constants/constants';
var CryptoJS = require("crypto-js");

const SplashScreen = () => {

    const [headerText, setHeaderText] = useState("");
    const [bodyText, setBodyText] = useState('');
    const [show, setShow] = useState(false);

    useEffect(() => {
        var userData = localStorage.getItem("user");
        if (userData && userData.length > 0) {
            var data = JSON.parse(userData);
            var bytes = CryptoJS.AES.decrypt(data, Constant.EncryptionKey);
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            if (!decryptedData.isSplashScreen) {
                SplashService.GetUserSplashScreen(decryptedData.userId).then(resp => {
                    if (resp && resp.isSuccess && resp.data != null) {
                        setBodyText(decodeEntities(resp.data.BodyText));
                        setHeaderText(resp.data.HeadLine);
                        setShow(true);
                    }
                });
            }
        }
    }, []);

    return (
        <div>
            <Dialog className='manage-splash-dialog' header={headerText} visible={show} style={{ width: '65vw' }} onHide={() => setShow(false)}>
                <div dangerouslySetInnerHTML={{ __html: bodyText }} />
            </Dialog>
        </div>
    )
}

export default SplashScreen;
export const importListingConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',
    CLEAR_ALL_MESSAGE: 'CLEAR_ALL_MESSAGE',
    GETALLIMPORTLISTING_REQUEST: 'GETALLIMPORTLISTING_REQUEST',
    GETALLIMPORTLISTING_REQUEST_END: 'GETALLIMPORTLISTING_REQUEST_END',

    GETALLIMPORTLISTING_SUCCESS: 'GETALLIMPORTLISTING_SUCCESS',
    GETALLIMPORTLISTING_FAILURE: 'GETALLIMPORTLISTING_FAILURE'

};

import { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../helpers';
import mdglogo from '../assets/img/logo.svg';
import HCPInsightslogo from '../assets/img/Hcp_insights_logo.png';
import AccessHCPlogo from '../assets/img/access-hcp-color-logo.png';
import DMSlogo from '../assets/img/DMS_logo.png';
import { removeAccessToken, removeRefreshToken } from '../services/token.service';
import { Link } from 'react-router-dom';
import { userService } from 'services/user.service';
import { GetPlateformType } from 'actions/header.action';
import { login } from 'actions/user.actions';

var CryptoJS = require("crypto-js");

interface IHeaderProps {
    onMenuToggle: any;
    themeToggler: any;
    isMenuOpen: any;
}

class Header extends Component<IHeaderProps, {}>{
    userDetail: any;

    constructor(props) {
        super(props);
        var userData = localStorage.getItem("user");
        if (userData && userData.length > 0) {
            var data = JSON.parse(userData);
            var bytes = CryptoJS.AES.decrypt(data, 'my-secret-key@123');
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.userDetail = decryptedData;
        }
    }
   
    getLogo = () => {
        let logo = [{ key:"DMS",value:DMSlogo }, { key:"AHCP", value:AccessHCPlogo }, { key:"HCPDI", value:HCPInsightslogo }]  
        return logo.find(x => x.key == sessionStorage.getItem("localMenu"))?.value || DMSlogo;
    }
    
    navigateToPage = (path) => {
        history.push(path);
    }

    signOut = () => {

        // localStorage.setItem("MDGReturnURL",window.location.pathname);

        if (this.userDetail !== undefined) {
            var UserLogoutRequest = {
                userId: this.userDetail.userId,
                userName: this.userDetail.userName
            }
            userService.Logout(UserLogoutRequest);
        }

        removeAccessToken();
        removeRefreshToken();
        localStorage.removeItem('queryListingRequest');
        localStorage.removeItem('importListingRequest');
        localStorage.removeItem('suppressionListingRequest');
        localStorage.removeItem('userListingRequest');
        localStorage.removeItem('cohortUploadListingRequest');
        localStorage.removeItem('preRunQueryListingRequest')
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userTheme');
        localStorage.removeItem('Menu');
        sessionStorage.removeItem("localMenu");
        localStorage.removeItem('IsDownloadURL');
        history.push("/login");
    }

    render() {
        
        return (
            <div className={this.props.isMenuOpen === true ? 'sidebaropen' : 'sidebarclose'}>
                <header className="mdg-layout-header app-header">
                    <div className="app-header-wrapper">
                        <div className='d-flex'>
                            <div className="nav-left ps-3">
                                <span className="header-left-title header-menuicon" onClick={this.props.onMenuToggle}><i className="fas fa-chevron-left"></i></span>
                            </div>
                            <div className="logo">
                                <Link title="Query/Export" className="text-decoration-underline-hover link-color p-menuitem-link"
                                    style={{ color: window.location.pathname === '/querylisting' ? '#0093e7' : '' }}
                                    to={{ pathname: '/querylisting' }}
                                > <img src={mdglogo} alt="mdo" height="25px" /></Link>

                            </div>
                        </div>
                        <div className="nav">
                            <div className="switch-platform-logo">
                                <div className="app-name">
                                    <div className='d-flex align-items-baseline appname-main'>
                                        <div className='appname-detail'>
                                            <img src={this.getLogo()} alt="mdo" height="35px" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nav-right">
                                <ul>
                                    <li className="nav-right-item">
                                        <div className="user-name">Hi, {this.userDetail !== undefined ? this.userDetail.fullName : ''} </div>
                                    </li>
                                    <li className='nav-right-item text-secondary px-0'>
                                        <span className='d-none d-md-block'>|</span>
                                    </li>
                                    <li className="nav-right-item">
                                        <div className="user-name d-none d-md-block">{this.userDetail?.userGroup}</div>
                                    </li>
                                    <li className="nav-right-item">
                                        <a href="#top" onClick={this.props.themeToggler}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 2 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-moon"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-sun"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>
                                        </a>
                                    </li>
                                    <li className="nav-right-item">
                                        <div className="dropdown use-dropdown">
                                            <a href="!#" className="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="fas fa-user"></i>
                                            </a>
                                            <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
                                                <li>
                                                <Link className="dropdown-item" to={{
                                                        pathname: '/myprofile',
                                                        state: { userId: this.userDetail ? this.userDetail?.userId : 0 }
                                                    }}><i className="fas fa-user me-2"></i>Profile </Link>
                                                </li>
                                                <li> <Link className="dropdown-item" to={{
                                                    pathname: '/changepassword'
                                                }}><i className="fas fa-lock me-2"></i>Change Password </Link></li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li><a href="!#" className="dropdown-item" onClick={(element) => { element.preventDefault(); this.signOut(); }}><i className="fas fa-power-off me-2"></i>Sign out</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        plateform: state.headers.plateform,
        userDetail: state.users.userDetail
    }
}

export default connect(mapStateToProps, { GetPlateformType: GetPlateformType, login: login })(Header);
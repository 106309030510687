import { criticalImpactLogListingConstants } from '../constants/criticalimpactloglisting.constant';


const initialState = {
  criticalImpactLogListingRequest: {},
  criticalImpactLogListingResponse: {
    lstCriticalImpactLogsResponse: [],
    totalRecords: 0
  },
  error: '',
  success: '',
  showCriticalImpactLogListingLoader: false
};

export function criticalImpactLogListing(state = initialState, action) {
  switch (action.type) {
    case criticalImpactLogListingConstants.GETALLCRITICALIMPACTLOGLISTING_REQUEST:
      return {
        ...state,
        showCriticalImpactLogListingLoader: action.showCriticalImpactLogListingLoader
      };
    case criticalImpactLogListingConstants.GETALLCRITICALIMPACTLOGLISTING_REQUEST_END:
      return {
        ...state,
        showCriticalImpactLogListingLoader: action.showCriticalImpactLogListingLoader
      };
    case criticalImpactLogListingConstants.GETALLCRITICALIMPACTLOGLISTING_SUCCESS:
      return {
        ...state,
        criticalImpactLogListingResponse: action.criticalImpactLogListingResponse

      };
    case criticalImpactLogListingConstants.GETALLCRITICALIMPACTLOGLISTING_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case criticalImpactLogListingConstants.CLEAR_ALL_MESSAGE:
      return {
        ...state,
        error: '',
        success: ''
      };
    default:
      return state
  }
}
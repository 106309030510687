import { downloadHistoryListingConstants } from '../constants/downloadhistorylisting.constants';

const initialState = {
    downloadHistoryListingRequest: {},
    downloadHistoryListingResponse: {
      downloadHistoryListingResponse: [],
      totalRecords: 0
    },
    error: '',
    success: '',
    showDownloadHistoryListingLoader: false
  };

  export function downloadHistoryListing(state = initialState, action) {
    switch (action.type) {
      case downloadHistoryListingConstants.GETALLDOWNLOADHISTORYLISTING_REQUEST:
        return {
          ...state,
          showDownloadHistoryListingLoader: action.showDownloadHistoryListingLoader
        };
      case downloadHistoryListingConstants.GETALLDOWNLOADHISTORYLISTING_REQUEST_END:
        return {
          ...state,
          showDownloadHistoryListingLoader: action.showDownloadHistoryListingLoader
        };
      case downloadHistoryListingConstants.GETALLDOWNLOADHISTORYLISTING_SUCCESS:
        return {
          ...state,
          downloadHistoryListingResponse: action.downloadHistoryListingResponse
  
        };
      case downloadHistoryListingConstants.GETALLDOWNLOADHISTORYLISTING_FAILURE:
        return {
          ...state,
          error: action.error
        };
      case downloadHistoryListingConstants.CLEAR_ALL_MESSAGE:
        return {
          ...state,
          error: '',
          success: ''
        };
      default:
        return state
    }
  }
import { postData } from '../helpers/api.helper';
var apiEndPoint = process.env.REACT_APP_API_URL;

export const SplashService = {
    SaveSplashScreen,
    GetUserSplashScreen
};

function SaveSplashScreen(model) {
    var url = apiEndPoint + "SplashScreen/SaveSplashScreen";
    return postData(url, model, 'SaveSpleshScreen');
}

function GetUserSplashScreen(userId) {
    var url = apiEndPoint + "SplashScreen/GetUserSplashScreen";
    return postData(url, {"UserId":userId}, 'GetUserSplashScreen');
}

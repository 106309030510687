import Loadable from 'react-loadable';
import loader from 'assets/img/loder-prospectone.gif';

function Load() {
    return (<div className="box-loader" >
        <div className="spin-loader-overlay"></div>
        <div className="spin-loader text-center">
            <img src={loader} width="50" height="50" alt="spin loader" />
            <p>Loading Data... </p>
        </div>
    </div>)
}

export const LoginComponent = Loadable({

    loader: () => import('pages/LoginPage/LoginForm'),
    loading: Load
})

export const QueryComponent = Loadable({

    loader: () => import('../pages/Query/CreateQuery'),
    loading: Load
})

export const QueryListComponent = Loadable({

    loader: () => import('../pages/Query/QueryListing'),
    loading: Load
})

export const QueryDetailComponent = Loadable({

    loader: () => import('pages/Query/QueryDetail'),
    loading: Load
})


export const ImportListingComponent = Loadable({

    loader: () => import('pages/Import/ImportListing'),
    loading: Load
})

export const ImportFileComponent = Loadable({

    loader: () => import('pages/Import/ImportFile'),
    loading: Load
})

export const ResetPasswordComponent = Loadable({

    loader: () => import('pages/LoginPage/ResetPassword'),
    loading: Load
})

export const SuppressionListingComponent = Loadable({

    loader: () => import('pages/Supression/SuppressionListing'),
    loading: Load
})

export const SuppressionFileComponent = Loadable({

    loader: () => import('pages/Supression/SuppressionFile'),
    loading: Load
})

export const UserListingComponent = Loadable({

    loader: () => import('pages/UserManagement/UserListing'),
    loading: Load
})

export const ManageUserComponent = Loadable({

    loader: () => import('pages/UserManagement/ManageUser'),
    loading: Load
})

export const CohortUploadListingComponent = Loadable({

    loader: () => import('pages/CohortUpload/CohortUploadListing'),
    loading: Load
})

export const CohortUploadFileComponent = Loadable({

    loader: () => import('pages/CohortUpload/CohortUploadFile'),
    loading: Load
})

export const PreRunListingComponent = Loadable({

    loader: () => import('pages/PreRunQuery/PreRunListing'),
    loading: Load
})
export const AudittrailListingComponent = Loadable({

    loader: () => import('pages/AuditTrail/AudittrailListing'),
    loading: Load
})
export const LicensableFiltersComponent = Loadable({

    loader: () => import('pages/LicensableFilters/LicensableFilters'),
    loading: Load
})

export const TableManagementListingComponent = Loadable({

    loader: () => import('pages/TableManagement/TableManagementListing'),
    loading: Load
})

export const TableManagementDetailComponent = Loadable({

    loader: () => import('pages/TableManagement/TableManagementDetail'),
    loading: Load
})

export const DownloadHistoryListingComponent = Loadable({

    loader: () => import('pages/DownloadHistory/DownloadHistoryListing'),
    loading: Load
})
export const ManageSplashScreenComponent = Loadable({

    loader: () => import('pages/SplashScreen/ManageSplashScreen'),
    loading: Load
})


export const FlaggedFileDownloadHistoryListingComponent = Loadable({

    loader: () => import('pages/FlaggedFileDownloadHistory/FlaggedFileDownloadHistoryListing'),
    loading: Load
})

export const ChangePasswordComponent = Loadable({

    loader: () => import('pages/UserManagement/ChangePassword'),
    loading: Load
})
export const ManageRoleComponent = Loadable({

    loader: () => import('pages/RoleManagement/ManageRole'),
    loading: Load
})
export const RoleComponent = Loadable({

    loader: () => import('pages/RoleManagement/Role'),
    loading: Load
})

export const SalesPersonListingComponent = Loadable({

    loader: () => import('pages/SalesPerson/SalesPersonListing'),
    loading: Load
})

export const DataSearchComponent = Loadable({

    loader: () => import('pages/DataSearch/DataSearch'),
    loading: Load
})

export const ManageSalesPersonComponent = Loadable({

    loader: () => import('pages/SalesPerson/ManageSalesPerson'),
    loading: Load
})
export const ManageExternalUserComponent = Loadable({

    loader: () => import('pages/UserManagement/ManageExternalUser'),
    loading: Load
})




export const ManageCampaignComponent = Loadable({

    loader: () => import('pages/HCPInsights/ManageCampaigns'),
    loading: Load
})

export const MyCampaignComponent = Loadable({

    loader: () => import('pages/HCPInsights/MyCampaigns'),
    loading: Load
})
export const ManageCustomerComponent = Loadable({

    loader: () => import('pages/HCPInsights/ManageCustomers'),
    loading: Load
})
export const DashboardComponent = Loadable({

    loader: () => import('pages/HCPInsights/Dashboard'),
    loading: Load
})

export const UploadDataComponent = Loadable({

    loader: () => import('pages/HCPInsights/UploadData'),
    loading: Load
})

export const SchedulerJobsComponent = Loadable({

    loader: () => import('pages/Hangfire/SchedulerJobs'),
    loading: Load
})

export const IdentityPartnerOnboardingListingComponent = Loadable({

    loader: () => import('pages/IdentityPartnerOnboarding/IdentityPartnerOnboardingListing'),
    loading: Load
})
export const DeepIntentListingComponent = Loadable({

    loader: () => import('pages/DeepIntent/DeepIntentListing'),
    loading: Load
})


export const DSPFlagMappingComponent = Loadable({

    loader: () => import('pages/DSPManagement/DSPFlagMapping'),
    loading: Load
})

export const DSPImportHistoryListingComponent = Loadable({

    loader: () => import('pages/DSPManagement/DSPImportHistoryListing'),
    loading: Load
})
export const CampaignOptimizationComponent = Loadable({

    loader: () => import('pages/HCPInsights/CampaignOptimization'),
    loading: Load
})

export const DSPActivationComponent = Loadable({

    loader: () => import('pages/DSPDistribution/DSPActivation'),
    loading: Load
})

export const DSPDistributionListingComponent = Loadable({

    loader: () => import('pages/DSPDistribution/DSPDistributionListing'),
    loading: Load
})

export const DownloadFileComponent = Loadable({

    loader: () => import('pages/Download/Download'),
    loading: Load
})

export const EmailConfirmationComponent = Loadable({

    loader: () => import('pages/SignUp/EmailConfirmation'),
    loading: Load
})

export const MyProfileComponent = Loadable({

    loader: () => import('pages/UserManagement/MyProfile'),
    loading: Load
})


export const RegistrationConfirmationComponent = Loadable({

    loader: () => import('pages/SignUp/RegistrationConfirmation'),
    loading: Load
})


export const SignUpComponent = Loadable({

    loader: () => import('pages/SignUp/SignUp'),
    loading: Load
})

export const accessdeniedComponent = Loadable({

    loader: () => import('pages/Permission/accessdenied'),
    loading: Load
})

export const PageNotFoundComponent = Loadable({

    loader: () => import('pages/PageNotFound/PageNotFoundPage'),
    loading: Load
})

export const ZerobounceListingComponent = Loadable({

    loader: () => import('pages/Zerobounce/ZerobounceListing'),
    loading: Load
})
export const CriticalImpactLoglistingComponent = Loadable({

    loader: () => import('pages/CriticalImpact/CriticalImpactLogListing'),
    loading: Load
})

import { rtaDistributionListingConstants } from '../constants/rtadistributionlisting.constants';



const initialState = {
    DspRtaListingRequest: {},
    DspRtaListingResponse: {
        lstRtaDistributionListingResponse: [],
        totalRecords: 0
    },
    error: '',
    success: '',
    showDspDistributionListingLoader: false
};
export function DspRtaListing(state = initialState, action) {
    switch (action.type) {
        case rtaDistributionListingConstants.GETALLDISTRIBUTIONLISTING_REQUEST:
            return {
                ...state,
                showDspDistributionListingLoader: action.showDspDistributionListingLoader
            };
        case rtaDistributionListingConstants.GETALLDISTRIBUTIONLISTING_REQUEST_END:
            return {
                ...state,
                showDspDistributionListingLoader: action.showDspDistributionListingLoader
            };
        case rtaDistributionListingConstants.GETALLDISTRIBUTIONLISTING_SUCCESS:
            return {
                ...state,
                DspRtaListingResponse: action.dspRtaListingResponse

            };
        case rtaDistributionListingConstants.GETALLDISTRIBUTIONLISTING_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case rtaDistributionListingConstants.CLEAR_ALL_MESSAGE:
            return {
                ...state,
                error: '',
                success: ''
            };
        default:
            return state
    }
}